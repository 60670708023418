import apiConfig from "../services/apiConfig";

export const ACCESS_TOKEN = "2c8dc2f5a6529fdb2536348a55fe4cd2000";
export const BASIC_AUTH = "cmVnaXN0ZXJlZF9kb25vcjo=";
export const REFRESH_TOKEN = "6c66b00";
export const VERIFY_CODE = "RtEw67ZUI6bg";

export const CHARITY_BASE_URL = "https://charity.karuna.lk";
export const RESOURCE_SERVER_URL = "https://resources.karuna.lk";

export const PRIVACY_POLICY_URL = `${RESOURCE_SERVER_URL}/pdf/Privacy_Policy.pdf?t=${new Date().getTime()}`;
export const TERM_AND_CONDITION_URL = `${RESOURCE_SERVER_URL}/pdf/Terms_and_Conditions.pdf?t=${new Date().getTime()}`;

export const CHARITY_URL = (lan = LAN_ENGLISH) => `${CHARITY_BASE_URL}/login?t=d&lan=${lan}`;
export const CHARITY_SIGNUP_URL = (lan = LAN_ENGLISH) => `${CHARITY_BASE_URL}/register?t=d&lan=${lan}`;
export const CHARITY_HOME = (lan = LAN_ENGLISH) => `${CHARITY_BASE_URL}/donor-verify?uKjehJlER=${localStorage.getItem(ACCESS_TOKEN)}&t=d&lan=${lan}`;

export const SHARE_URL_BASE_PATH = (path) => `${apiConfig.serverUrl}/${apiConfig.basePath}/program/${path}`;

export const ROUTE_PATH = "";
export const HOME_PATH = `${ROUTE_PATH}/home`;
export const LAUNCH_PATH = `${ROUTE_PATH}/launch`;
export const LOGIN_PATH = `${ROUTE_PATH}/login`;
export const FORGOT_PASSWORD_PATH = `${ROUTE_PATH}/forgot-password`;
export const FORGOT_PASSWORD_OTP_PATH = `${ROUTE_PATH}/forgot-password-otp`;
export const FORGOT_PASSWORD_CHANGE_PATH = `${ROUTE_PATH}/forgot-password-change`;
export const REGISTRATION_PATH = `${ROUTE_PATH}/register`;
export const FUNDRAISING_PATH = `${ROUTE_PATH}/fundraising`;
export const SELECTED_PROGRAM_PATH = `${ROUTE_PATH}/program`;
export const MANUDAM_MEHEWARA_PATH = `${ROUTE_PATH}/manudammehewara`;
export const SHAREABLE_PROGRAM_PATH = `${ROUTE_PATH}/shareable-program`;
export const NON_MONITORY_SELECTED_PROGRAM_PATH = `${ROUTE_PATH}/non-monitory-selected-program`;
export const CHARITY_PROGRAM_PATH = `${ROUTE_PATH}/charity-programmes`;
export const COMMUNITY_RECOMMENDATION_PATH = `${ROUTE_PATH}/community-recommendation`;
export const SET_ADDRESS_PATH = `${ROUTE_PATH}/set-address`;
export const INTEREST_PATH = `${ROUTE_PATH}/interest`;
export const ERROR_PATH = `${ROUTE_PATH}/error`;
export const PAGE_NOT_FOUND_PATH = `${ROUTE_PATH}/page-not-found`;
export const OTP_PATH = `${ROUTE_PATH}/otp`;

export const DO_PAYMENT_PATH = `${ROUTE_PATH}/do-payment`;
export const PAYMENT_SUCCESS_PATH = `${ROUTE_PATH}/genie/callback`;
export const PAYMENT_EZ_CASH_SUCCESS_PATH = `${ROUTE_PATH}/ez-cash/callback`;
export const PAYMENT_OTHER_SUCCESS_PATH = `${ROUTE_PATH}/payment/success`;

export const ALL_CATEGORIES_PATH = `${ROUTE_PATH}/all-categories`;
export const SELECTED_CATEGORY_PATH = `${ROUTE_PATH}/category`;

export const PROFILE_DETAILS_PATH = `${ROUTE_PATH}/profile`;
export const PROFILE_EDIT_PATH = `${ROUTE_PATH}/edit-profile`;
export const PDF_PATH = `${ROUTE_PATH}/pdf`;
export const CONTACT_US_PATH = `${ROUTE_PATH}/contact-us`;
export const ABOUT_US_PATH = `${ROUTE_PATH}/about-us`;
export const HOW_IT_WORK_PATH = `${ROUTE_PATH}/how-it-work`;
export const FANDQS_PATH = `${ROUTE_PATH}/f&qs`;

export const STEP_GENERAL = "General";
export const STEP_LOCATION = "Location";
export const STEP_VALIDATION = "Validation";
export const STEP_ACCOUNT_DETAILS = "Account-Details";

export const LAN = "lang";

export const LAN_ENGLISH = "en";
export const LAN_SINHALA = "si";
export const LAN_TAMIL = "ta";
