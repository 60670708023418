export const AS_DEV_USER = false;
const path = {
    url: 'https://app.karuna.lk',
    basePath: 'api/v1'
};

const conf = {
    serverUrl: path.url,
    basePath: path.basePath,
    port: 80
};

export default conf
